import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Checkbox, Input, Select, Button } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import toastError from "../common/toastError";

const axiosApiInstance = axios.create();
const { TextArea } = Input;

const CreateQuestions = (props) => {
  const [quizList, setQuizList] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [choices, setChoices] = useState([{ choice: "", is_answer: false }]);
  const [fileData, setFile] = useState([]);
  const [image, setImg] = useState(null);
  const [imgName, setImgName] = useState("");
  const [explaination, setExplaination] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isright, setRight] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      let x = [
        {
          question: "",
          answer1: "",
          answer2: "",
          answer3: "",
          answer4: "",
          correct: "",
          weight: "",
          display_order: "",
          explanation: "",
        },
      ];
      setFile(x);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    try {
      const getTraining = async () => {
        let result = await axiosApiInstance.get(APILINK + `/create_training`);
        if (result.data.data) {
          setTrainingList(result.data.data);
          console.log(trainingList);
        }
      };
      getTraining();
    } catch (e) {
      // console.log(e);
    }
  }, []);

  const onSubmit = async (data) => {
    setDisabled(true);
    // data.choices = choices;

    const isEmpty = choices.reduce((prev, cur) => {
      if (cur.choice === "") return true;
      else return prev;
    }, false);

    if (isEmpty) {
      toast.error("Please enter all choices.");
      setDisabled(false);
      return;
    }

    let result;
    if (image == null) {
      data.explanation = explaination;
      data.choices = choices;
      // data.image = image;
      // data.image_name = imgName;
      if (props.isQuiz) {
        data.quiz_id = props.match.params.id;
      } else {
        data.training_id = props.match.params.id;
      }
    } else {
      data.explanation = explaination;
      data.choices = choices;
      data.image = image;
      data.image_name = imgName;
      if (props.isQuiz) {
        data.quiz_id = props.match.params.id;
      } else {
        data.training_id = props.match.params.id;
      }
    }

    console.log(data);
    let answers = data.choices.map((choice) => {
      return choice.is_answer;
    });
    console.log(answers);
    let falseAnswers = answers.filter((answer) => answer == false);
    console.log(falseAnswers);
    if (answers.length === falseAnswers.length) {
      setDisabled(false);
      setRight(false);
      setDisabled(false);
    } else {
      setRight(true);
      result = await axiosApiInstance
        .post(APILINK + `/create_question`, data)
        .catch((err) => {
          toastError(err.response.data);
          setDisabled(false);
        });

      if (result) {
        setTimeout(() => {
          toast.info("Question has been created successfully");
        }, 500);
        setTimeout(() => {
          if (props.isQuiz) {
            props.history.push(`/quiz_questions/${props.match.params.id}`);
          } else {
            props.history.push(`/training_questions/${props.match.params.id}`);
          }
        }, 3000);
      }
    }
  };

  const handleAddClick = () => {
    let choicesList = [...choices];
    setChoices([...choicesList, { choice: "", is_answer: false }]);
  };
  const handleChoice = (e, index) => {
    const { name, value, checked } = e.target;

    const list = [...choices];
    console.log(list);
    if (name === "is_answer") {
      list[index][name] = checked;
    } else {
      list[index][name] = value;
    }

    setChoices(list);
    console.log(choices);
  };

  const downLoadCatalog = (e) => {
    downloadCSV2(fileData);
  };
  const explainHandler = (e) => {
    setExplaination(e.target.value);
  };
  const convertArrayOfObjectsToCSV2 = (array) => {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(fileData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  const downloadCSV2 = (array) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV2(array);
    if (csv == null) return;

    let filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  const uploadFile = async (e) => {
    const eventdata = new FormData();
    if (props.isQuiz) {
      eventdata.append("file", e.target.files[0]);
      eventdata.append("quiz_id", props.match.params.id);
    } else {
      eventdata.append("file", e.target.files[0]);
      eventdata.append("training_id", props.match.params.id);
    }

    let result = await axiosApiInstance
      .post(APILINK + `/upload_questions`, eventdata)
      .catch((err) => {
        console.log(err.response);
      });
    console.log(result);
    if (result) {
      if (result.data == "Created Successfuly") {
        setTimeout(() => {
          toast.info("Question has been created successfully");
        }, 500);
        if (!props.isQuiz) {
          setTimeout(() => {
            props.history.push(`/training_questions/${props.match.params.id}`);
          }, 2000);
        } else {
          setTimeout(() => {
            props.history.push(`/quiz_questions/${props.match.params.id}`);
          }, 2000);
        }
      }
    }
  };

  const onFileUpload = (e) => {
    e.preventDefault();
    console.log(e.target.files[0].name);
    setImgName(e.target.files[0].name);

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      setImg(e.target.result);
    };
  };
  return (
    <Container className="viewSuper align-left">
      <Row>
        <Col sm="4">
          {" "}
          <h2>Create Question</h2>
        </Col>
        <Col sm="8">
          {" "}
          <Button onClick={downLoadCatalog} className="downloadBtn mr-4">
            Download Questions Template
          </Button>
          <input onChange={uploadFile} type="file" className="uploadBtn" />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={6}>
                <label className="label">Question</label>
                <textarea
                  rows={5}
                  type="text"
                  {...register("question", { required: true })}
                />

                <p className="error">
                  {errors.question?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>

              <Col lg={6}>
                <label className="label">Weight</label>
                {props.isQuiz ? (
                  <React.Fragment>
                    {" "}
                    <Input
                      type="number"
                      {...register("weight", { required: true, min: 1 })}
                    />
                    <p className="error">
                      {errors.weight?.type === "required" &&
                        "This field is required"}
                    </p>
                    <p className="error">
                      {errors.weight?.type === "min" &&
                        "Weight can not be less than one"}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Input type="number" {...register("weight")} />
                  </React.Fragment>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                {choices.map((choice, i) => {
                  return (
                    <React.Fragment>
                      <label>Choice {i + 1}</label>

                      <Input
                        name="choice"
                        type="text"
                        required={true}
                        onChange={(e) => handleChoice(e, i)}
                      />
                      <Checkbox
                        name="is_answer"
                        onChange={(e) => handleChoice(e, i)}
                      >
                        Correct Answer
                      </Checkbox>
                      <div className="btn-box">
                        {choices.length - 1 === i && (
                          <Button onClick={handleAddClick}>Add Choice</Button>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
              </Col>
              <Col sm="12" md="6">
                <label className="label">Display Order</label>
                <Input type="number" {...register("display_order")} />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Label>Upload Image</Form.Label>
                <div className="wrapper">
                  <div className="file-upload">
                    <input onChange={onFileUpload} type="file" />
                    <i className="fa fa-plus"></i>
                  </div>
                </div>{" "}
                <p>{imgName}</p>
              </Col>
            </Row>
            <Row>
              {/* {props.isQuiz ? null : ( */}
              <Col sm="6">
                <label className="label">Explaination</label>

                <Input.TextArea
                  rows={4}
                  type="text"
                  value={explaination}
                  onChange={explainHandler}
                />
              </Col>
              {/* )} */}
            </Row>
            <Row>
              <Col style={{ textAlign: "end" }} sm="12">
                {" "}
                <Button
                  disabled={disabled}
                  className="forumBtn1"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Create
                </Button>
                {isright ? null : (
                  <p className="error">choose correct answer first!</p>
                )}
              </Col>
            </Row>
          </form>
        </Col>
      </Row>

    </Container>
  );
};
const mapStateToProps = (state) => ({
  isQuiz: state.isQuiz.isQuiz,
});
export default connect(mapStateToProps)(
  ErrorHandler(CreateQuestions, axiosApiInstance)
);
