import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Table,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Radio,
  TimePicker,
  Pagination,
  Spin,
  message,
} from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isSubject } from "../../global-state/actions/isSubjectAction";

const axiosApiInstance = axios.create();

class ViewAllRounds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tutors: [],
      data2: [],
      count: 1,
      loading: false,
      policy: "",
      current: 1,
      cost: 100,
      imageName: "",
      showSubjectModel: false,
      showSubjectRoundForm: false,
      showEnroll: false,
      showEdit: false,
      visaPay: false,
      transferPay: false,
      startDate: "",
      endDate: "",
      showDelete: false,
      disabled: false,
      errSubmit: "",
      image: null,
      tutornextUrl: null,
      payPal: false,
      startTime: "00:00:00",
      currentSubject: 1,
      countSubject: 1,
      isSecondPayment: false,
      columns: [
        {
          name: "Round Version",
          selector: "version",
          sortable: true,
          right: true,
        },
        {
          name: "Start Date",
          selector: "start_date.date",
          sortable: true,
          right: true,
        },
        {
          name: "End Date",
          selector: "end_date.date",
          sortable: true,
          right: true,
        },
        {
          name: "Actions",
          button: true,
          width: "220px",
          cell: (row) => (
            <div className="table-data-feature">
              {this.props.user.user_type === "3" ? (
                <React.Fragment>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Start Round</Tooltip>
                    }
                  >
                    <i
                      id={row.id}
                      onClick={this.startRound}
                      className="fas fa-play-circle pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Delete Round</Tooltip>
                    }
                  >
                    <i
                      id={row.id}
                      onClick={this.showWarning}
                      className="fas fa-trash pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Select Subjects</Tooltip>
                    }
                  >
                    <i
                      id={row.id}
                      onClick={this.openSubject}
                      className="fas fa-plus pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Edit Round</Tooltip>
                    }
                  >
                    <i
                      onClick={this.showEdit}
                      id={row.id}
                      className="fas fa-edit pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Subject Round
                      </Tooltip>
                    }
                  >
                    <Link to={`/subject-rounds/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Create Training</Tooltip>
                    }
                  >
                    <Link to={`/create_training/${row.id}`}>
                      {" "}
                      <i
                        onClick={this.props.isSubject(false)}
                        className="fas fa-plus-circle pr-2"
                      ></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Trainings</Tooltip>
                    }
                  >
                    <Link to={`/view_training/${row.id}`}>
                      {" "}
                      <i
                        onClick={this.props.isSubject(false)}
                        className="fas fa-eye pr-2"
                      ></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Forum</Tooltip>
                    }
                  >
                    <Link to={`/view_round_fourm/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                </React.Fragment>
              ) : this.props.user.user_type === "2" ? (
                <React.Fragment>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Subject Round
                      </Tooltip>
                    }
                  >
                    <Link to={`/subject-rounds/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-4"></i>
                    </Link>
                  </OverlayTrigger>

                  {row.enroll.status === "Enroll" ||
                  (row.enroll.can_enroll && row.enroll.status !== "Refused") ? (
                    <Button
                      className="forumBtn1"
                      id={row.id}
                      onClick={(e) => {
                        if (row.enroll.can_enroll) {
                          this.setState({ isSecondPayment: true });
                        } else {
                          this.setState({ isSecondPayment: false });
                        }
                        this.showEnrollModal(e);
                      }}
                    >
                      {row.enroll.can_enroll ? (
                        <>
                          2<sup>nd</sup> Enroll
                        </>
                      ) : (
                        <>Enroll</>
                      )}
                    </Button>
                  ) : (
                    <Button
                      className="forumBtn1"
                      id={row.id}
                      onClick={this.showEnrollModal}
                      disabled={row.enroll.status != "Enroll"}
                    >
                      {row.enroll.status ?? "Enroll"}
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Subject Round
                      </Tooltip>
                    }
                  >
                    <Link to={`/subject-rounds/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Enrolled Students
                      </Tooltip>
                    }
                  >
                    <Link to={`/view_students/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Forum</Tooltip>
                    }
                  >
                    <Link to={`/view_round_fourm/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                </React.Fragment>
              )}
            </div>
          ),
        },
      ],
      disableProceed: false,
      paymentMethod: "",
      subjRoundstartDate: "",
      subjRoundendDate: "",
      selectedTutor: null,
      disabledSubRound: false,
    };
  }

  startRound = async (e) => {
    let result = await axiosApiInstance.get(
      APILINK + `/start_course_round/${e.target.id}`
    );
    if (result) {
      setTimeout(() => {
        toast.info(result.data);
      }, 1000);
    }
  };
  visaPay = () => {
    this.setState({ visaPay: true, transferPay: false });
  };

  transferPay = () => {
    this.setState({ visaPay: false, transferPay: true });
  };

  payPal = () => {
    this.setState({ payPal: true, transferPay: false });
  };

  handleImage = (e) => {
    this.setState({
      errSubmit: "",
      disableProceed: false,
    });
    console.log(e.target.files);
    this.setState({
      image: e.target.files[0],
      imageName: e.target.files[0].name,
    });
  };

  selectSubject = (e) => {
    console.log("radio checked", e);

    this.setState({ subjectID: e.target.value });
    if (e.target.checked) {
      this.setState({ showSubjectRoundForm: true });
    }
  };

  showEnrollModal = (e) => {
    console.log(e.target);
    this.setState({ roundID: e.target.id, showEnroll: true }, () => {
      console.log(this.state.roundID);
    });
  };
  hideEnrollModal = (e) => {
    this.setState({ showEnroll: false });
  };

  getRounds = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.user.user_type == "1") {
      result = await axiosApiInstance.get(
        APILINK + `/create_course_round?page=1`
      );
    } else {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_course_round?course_id=${this.props.match.params.id}&page=1`
      );
    }

    console.log(result);
    this.setState({
      data: result.data.data,
      count: result.data.count,
      loading: false,
    });
  };

  componentDidMount() {
    this.getRounds();
    if (this.props.user.user_type == 2) {
      this.success();
    }
  }

  success = () => {
    message.warning(
      "Please note that you can join course rounds That has already started",
      10
    );
  };
  openSubject = (e) => {
    console.log(e.target.id);
    this.getSubjects();
    this.setState({ showSubjectModel: true, courseRoundID: e.target.id });
  };
  hideSubject = () => {
    this.setState({ showSubjectModel: false });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showEdit = (e) => {
    console.log(e.target.id);
    this.setState({ roundID: e.target.id }, () => {
      this.setState({ showEdit: true });
      this.getRoundDetails();
    });
  };
  hideEdit = (e) => {
    this.setState({ showEdit: false });
  };

  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };
  handleEndDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ endDate: dateString });
  };

  getRoundDetails = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/course_round_details/${this.state.roundID}`
    );
    console.log(result);
    this.setState({
      version: result.data.version,
      startDate: result.data.start_date.date,
      endDate: result.data.end_date.date,
    });
  };

  editCourseRound = async () => {
    let data = {
      start_date_: this.state.startDate,
      end_date_: this.state.endDate,
      version: this.state.version,
    };
    if (this.state.endDate <= this.state.startDate) {
      toast.error("Please Enter Valid Date!");
    } else {
      let result = await axiosApiInstance.patch(
        APILINK + `/update_course_round/${this.state.roundID}`,
        data
      );
      if (result) {
        console.log(result);
        setTimeout(() => {
          toast.info(`Course Round updated Successfully`);
        }, 500);
        setTimeout(() => {
          this.setState({ showEdit: false });
        }, 3000);
      }
    }
  };

  showWarning = (e) => {
    this.setState({ showDelete: true, roundID: e.target.id });
  };
  close = (e) => {
    this.setState({ showDelete: false });
  };
  deleteRound = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/update_course_round/${this.state.roundID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ showDelete: false });
      this.getRounds();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showEdit !== this.state.showEdit) {
      this.getRounds();
    }
  }

  enrollRound = async () => {
    this.setState({ disabled: true, disableProceed: true });
    let data;
    let result;
    let formData = new FormData();
    if (this.state.paymentMethod == "1") {
      data = {
        course_round_id: this.state.roundID,
        installment_policy: this.state.policy,
      };
      result = await axiosApiInstance
        .get(
          APILINK +
            `/pay_course?course_round_id=${this.state.roundID}&installment_policy=${this.state.policy}`
        )
        .catch((err) => {
          console.log(err.response);
          this.setState({ disableProceed: false });
          setTimeout(() => {
            toast.error(err.response.data);
          }, 500);
        });
      if (result) {
        window.location.href = `https://accept.paymobsolutions.com/api/acceptance/iframes/${result.data.data.iframe_id}?payment_token=${result.data.data.token}`;
      }
    } else if (this.state.paymentMethod == "2") {
      console.log("heree");

      result = await axiosApiInstance
        .get(
          APILINK +
            `/pay_course?course_round_id=${this.state.roundID}&installment_policy=${this.state.policy}&type=paypal`
        )
        .catch((err) => {
          console.log(err.response);
          this.setState({ disableProceed: false });

          setTimeout(() => {
            toast.error(err.response.data);
          }, 500);
        });
      console.log(result);
      if (result) {
        window.location.href = result.data.data.iframe_id;
      }
    } else {
      if (this.state.image === null) {
        this.setState({
          errSubmit: "You need to upload image first !",
          // disableProceed: false
        });
      } else {
        this.setState({ errSubmit: "" });

        formData.append("course_round_id", this.state.roundID);
        formData.append("installment_policy", this.state.policy);
        formData.append("image", this.state.image);

        result = await axiosApiInstance
          .post(APILINK + `/pay_avec_account`, formData)
          .catch((err) => {
            console.log(err.response);
            this.setState({ disableProceed: false });

            setTimeout(() => {
              toast.error(err.response.data);
            }, 500);
          });

        if (result) {
          setTimeout(() => {
            toast.info(`You have enrolled successfully`);
          }, 500);
          setTimeout(() => {
            this.setState({ showEnroll: false });
            this.props.history.push("/courses");
          }, 3000);
        }
      }
    }
    this.setState({ disabled: true });
  };

  getSubjects = async () => {
    const result = await axiosApiInstance.get(
      APILINK +
        `/create_subjects?course_id=${this.props.match.params.id}&page=1`
    );
    console.log(result);
    let sorted = result.data.data.sort((a, b) =>
      a.display_order > b.display_order ? 1 : -1
    );
    // console.log(sorted);
    this.setState({ data2: sorted, countSubject: result.data.count });
  };

  getAllTutors = async () => {
    let result = await axiosApiInstance.get(
      APILINK + "/tutor_signup?user_type=1"
    );
    this.setState(
      { tutors: result.data.data, tutornextUrl: result.data.next },
      () => {
        console.log(this.state.tutors);
      }
    );
  };
  handleTime = (time, timeString) => {
    this.setState({ startTime: timeString });
  };
  handleSubjectRoundStart = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ subjRoundstartDate: dateString });
  };
  handleSubjectRoundEnd = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ subjRoundendDate: dateString });
  };
  handleTutor = (e) => {
    this.setState({ selectedTutor: e, selectedTutorID: e });
  };

  createSubjectRound = async () => {
    this.setState({ disabledSubRound: true });
    if (this.state.subjRoundendDate <= this.state.subjRoundstartDate) {
      setTimeout(() => {
        toast.error(`Please Enter valid Date !`);
      }, 500);
      this.setState({ disabledSubRound: false });
    } else {
      if (
        this.state.subjRoundstartDate == "" ||
        this.state.subjRoundendDate == "" ||
        this.state.selectedTutor == "" ||
        this.state.startTime == ""
      ) {
        setTimeout(() => {
          toast.error(`Please fill out all fields !`);
        }, 500);
        this.setState({ disabledSubRound: false });
      } else {
        let data = {
          tutor_id: this.state.selectedTutor,
          subject_id: this.state.subjectID,
          course_round_id: this.state.courseRoundID,
          start_date_: this.state.subjRoundstartDate,
          end_date_: this.state.subjRoundendDate,
          start_time: this.state.startTime,
        };
        console.log(data);
        let result = await axiosApiInstance
          .post(APILINK + "/create_subject_round", data)
          .catch((err) => {
            console.log(err.response);
            for (const error in err.response.data) {
              console.log(err.response.data[error]);
              setTimeout(() => {
                toast.error(`${err.response.data[error]}`);
              }, 500);
            }
            this.setState({ disabledSubRound: false });
          });
        console.log(result);
        if (result) {
          if (result.data) {
            this.setState({
              showSubjectRoundForm: false,
              showSubjectModel: false,
            });
            setTimeout(() => {
              toast.info(`A new subject round created successfully`);
            }, 500);
            setTimeout(() => {
              this.props.history.push(
                `/subject-rounds/${this.state.courseRoundID}`
              );
            }, 3000);
            this.setState({ disabledSubRound: true });
          }
        }
      }
    }
  };

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result;
        if (this.props.user.user_type == "1") {
          result = await axiosApiInstance
            .get(APILINK + `/create_course_round?page=${page}`)
            .then((res) => {
              console.log(res);
              this.setState({ data: res.data.data, count: res.data.count });
            });
        } else {
          result = await axiosApiInstance
            .get(
              APILINK +
                `/create_course_round?course_id=${this.props.match.params.id}&page=${page}`
            )
            .then((res) => {
              console.log(res);
              this.setState({ data: res.data.data, count: res.data.count });
            });
        }
      }
    );
  };

  onScroll = (event) => {
    var target = event.target;
    if (
      !this.state.loadTutor &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      this.setState({ loadTutor: true }, async () => {
        target.scrollTo(0, target.scrollHeight);

        if (this.state.tutornextUrl !== null) {
          const result = await axiosApiInstance.get(
            `${this.state.tutornextUrl}`
          );

          this.setState({
            tutornextUrl: result.data.next,
          });

          var children = [...this.state.tutors, ...result.data.data];
          this.setState({ tutors: children }, () => {
            this.setState({ loadTutor: false });
          });
        }
      });
    }
  };

  onChangeSubjects = (page) => {
    console.log(page);
    this.setState(
      {
        currentSubject: page,
      },
      async () => {
        let result;
        if (this.props.user.user_type == "1") {
          result = await axiosApiInstance.get(APILINK + `/create_subjects`);
        } else {
          result = await axiosApiInstance.get(
            APILINK +
              `/create_subjects?course_id=${this.props.match.params.id}&page=${this.state.currentSubject}`
          );
        }

        console.log(result);
        let sorted = result.data.data.sort((a, b) =>
          a.display_order > b.display_order ? 1 : -1
        );
        // console.log(sorted);
        this.setState({ data2: sorted, countSubject: result.data.count });
      }
    );
  };

  render() {
    // console.log(this.state.data[0].course)
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col>
                <h1 className="course-title">
                  {this.state.data.length > 0
                    ? this.state.data[0].course.title
                    : null}
                </h1>
                <p className="course-description">
                  {this.state.data.length > 0
                    ? this.state.data[0].course.description
                    : null}
                </p>
              </Col>
            </Row>
            {this.props.user.user_type == "1" ? null : (
              <Row>
                <Col className="course-details-keys">
                  {this.state.data.length > 0 ? (
                    <>
                      <p>
                        Fees (1 installment) :{this.state.data[0].course.cost}
                      </p>
                      <p>
                        Fees (2 installments) :
                        {this.state.data[0].course.installments_cost}
                      </p>
                    </>
                  ) : null}{" "}
                </Col>
              </Row>
            )}

            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h2> All Course Rounds </h2>
              </Col>
              {this.props.user.user_type === "3" ? (
                <Col className="p-4 mb-4" style={{ textAlign: "right" }} sm={6}>
                  <Link to={`/add_round/${this.props.match.params.id}`}>
                    {" "}
                    <Button className="forumBtn1">Add New Round</Button>
                  </Link>
                </Col>
              ) : null}
            </Row>

            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}

        <Modal show={this.state.showSubjectModel} onHide={this.hideSubject}>
          <Container className="p-2" fluid>
            <Row className="m-2">
              <Col className="text-center pt-2" sm={12}>
                {" "}
                <h6>Select Subject for this round</h6>
              </Col>
            </Row>
            {this.state.data2.length !== 0 ? (
              <Row>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Subject Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data2.map((x) => {
                      return (
                        <tr>
                          <td>{x.title}</td>
                          <td>
                            <Radio.Group
                              onChange={this.selectSubject}
                              value={this.state.subjectID}
                              name={x.title}
                            >
                              <Radio id={x.id} value={x.id}></Radio>
                            </Radio.Group>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            ) : null}

            <Pagination
              onChange={this.onChangeSubjects}
              defaultCurrent={this.state.currentSubject}
              total={this.state.countSubject}
              current={this.state.currentSubject}
            />
          </Container>
          {this.state.showSubjectRoundForm ? (
            <Container fluid>
              <Row>
                <Col className="text-center pt-2" sm={12}>
                  {" "}
                  <h6>Create Subject Round</h6>
                </Col>
              </Row>
              <Form id="infoForm">
                <Row className="mt-2">
                  <Col sm={6}>
                    <Form.Label>
                      <strong style={{ color: "red" }}>*</strong>subject start
                      date
                    </Form.Label>

                    <DatePicker onChange={this.handleSubjectRoundStart} />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>
                      <strong style={{ color: "red" }}>*</strong>subject end
                      date
                    </Form.Label>

                    <DatePicker onChange={this.handleSubjectRoundEnd} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Label>
                      <strong style={{ color: "red" }}>*</strong>Select Tutor{" "}
                    </Form.Label>
                    <Select
                      allowClear
                      placeholder="Select Tutor"
                      value={this.state.selectedTutor}
                      onChange={this.handleTutor}
                      onClick={this.getAllTutors}
                      showSearch
                      className="selectTutor"
                      onPopupScroll={this.onScroll}
                      // style={{height: "32px"}}
                    >
                      {this.state.tutors.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.full_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    <Form.Label>
                      <strong style={{ color: "red" }}>*</strong>Start Time
                    </Form.Label>
                    <TimePicker
                      onChange={this.handleTime}
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    />
                  </Col>
                </Row>
              </Form>

              <Row style={{ margin: "auto" }}>
                <Col className="text-center my-4" md={12}>
                  <Button
                    className="ml-3 mb-3 forumBtn1"
                    onClick={this.createSubjectRound}
                    disabled={this.state.disabledSubRound}
                  >
                    create
                  </Button>
                </Col>
              </Row>
            </Container>
          ) : null}
        </Modal>

        <Modal show={this.state.showEdit} onHide={this.hideEdit}>
          <Container fluid className="py-4">
            <Form id="infoForm">
              <Row>
                <Col className="text-center pt-2" sm={12}>
                  {" "}
                  <h6>Edit Round</h6>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={6}>
                  <Form.Label>Course start date</Form.Label>

                  <DatePicker
                    value={moment(this.state.startDate, "YYYY/MM/DD")}
                    onChange={this.handleStartDate}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Course end date</Form.Label>

                  <DatePicker
                    value={moment(this.state.endDate, "YYYY/MM/DD")}
                    onChange={this.handleEndDate}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={6}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Version</Form.Label>
                    <Form.Control
                      name="version"
                      value={this.state.version}
                      onChange={this.handleChange}
                      type="number"
                      placeholder="Version"
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mt-4">
                  {" "}
                  <Button
                    className="mt-5 forumBtn1"
                    onClick={this.editCourseRound}
                  >
                    Edit
                  </Button>{" "}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal>

        <Modal show={this.state.showEnroll} onHide={this.hideEnrollModal}>
          <Container fluid className="py-4">
            <Form id="infoForm">
              <Row>
                <Col className="text-center pt-2" sm={12}>
                  {" "}
                  <h4>Enroll in This Round</h4>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={12}>
                  <Form.Group controlId="exampleForm.ControlInput">
                    <Form.Label>
                      <strong style={{ color: "red" }}>*</strong>Installment
                      Policy
                    </Form.Label>
                    <Form.Control
                      name="policy"
                      value={this.state.policy}
                      onChange={this.handleChange}
                      as="select"
                    >
                      <option value="0" selected hidden>
                        Choose installment policy
                      </option>
                      {this.state.data.length > 0 ? (
                        this.state.data[0].course.installment_policy == "2" ? (
                          <option value="2">100% Payment</option>
                        ) : (
                          <>
                            {this.state.isSecondPayment ? undefined : (
                              <option value="2">100% Payment</option>
                            )}
                            <option value="1">
                              {this.state.isSecondPayment && "Second "}50%
                              Deposit
                            </option>
                          </>
                        )
                      ) : null}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput">
                    <Form.Label>
                      <strong style={{ color: "red" }}>*</strong>Payment Method
                    </Form.Label>
                    <Form.Control
                      name="paymentMethod"
                      value={this.state.paymentMethod}
                      onChange={this.handleChange}
                      as="select"
                    >
                      <option value="0" selected hidden>
                        Choose Payment Method
                      </option>
                      {/* <option value="1"> Pay via Paymob</option> */}
                      <option value="2">Credit card payment</option>
                      <option value="3">Bank deposit</option>
                    </Form.Control>
                  </Form.Group>
                  <Row>
                    <Col sm={12}>
                      {this.state.paymentMethod == "3" ? (
                        <div className="mt-4 p-2">
                          <p>
                            Please depoist the amount of{" "}
                            <strong>
                              {this.state.policy == "2"
                                ? this.state.data[0].course.cost
                                : this.state.data[0].course.installments_cost /
                                  2}
                            </strong>{" "}
                            CAD into the following bank account, and upload a
                            photo of the Payment receipt to proceed.
                          </p>
                          <p>
                            Account holder name:{" "}
                            <strong>Mohamed Wahied Mohamed Elshafey</strong>
                          </p>
                          <p>
                            Bank: <strong>Bank Misr</strong>
                          </p>
                          <p>
                            Branch: <strong>Minia al kamah, Sharkia</strong>
                          </p>
                          <p>
                            Account number: <strong>22503-32000-000635</strong>
                          </p>
                          <p>
                            IBAN number:{" "}
                            <strong>EG700002022502250332000000635</strong>
                          </p>
                          <p>
                            Swift code: <strong>BMISEGCX140</strong>
                          </p>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  {this.state.paymentMethod == "3" ? (
                    <React.Fragment>
                      {" "}
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Receipt Image</Form.Label>
                        <Form.Control onChange={this.handleImage} type="file" />
                      </Form.Group>
                      <p className="error"> {this.state.errSubmit}</p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Col>
              </Row>

              <Row className="mt-2">
                <Col sm={12} className="text-right">
                  {" "}
                  <Button
                    className="mt-2 action-btn"
                    onClick={this.enrollRound}
                    disabled={this.state.disableProceed}
                  >
                    Proceed <i class="fas fa-chevron-right"></i>
                  </Button>{" "}
                </Col>
              </Row>
            </Form>
          </Container>
          <i
            onClick={this.hideEnrollModal}
            class="exit far fa-times-circle"
          ></i>
        </Modal>

        <Modal show={this.state.showDelete} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Round?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteRound} className="forumBtn1 mx-3">
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>

        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isSubject }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(ViewAllRounds, axiosApiInstance));
