import React, { Component } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import "../../style-sheets/training.css";
import { Radio } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Input, Pagination, message, Checkbox } from "antd";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import generateQuestionsExcel from "../../utils/questions-excel-genrator";
import { Link } from "react-router-dom";

const axiosApiInstance = axios.create();

class ViewTrainingQues extends Component {
  state = {
    loading: false,
    questions: [],
    answerIds: {},
    count: 0,
    checkAnswer: "",
    show: false,
    disable: true,
    current: 1,
    showExplain: false,
    questionNumber: "",
    showWarning: false,
    questionCount: 0,
    question: "",
    showEdit: false,
    showEditChoice: false,
    choiceId: "",
    showDelete: false,
    showEditImg: false,
    selectedFile: null,
    isCorrect: false,
    showEditOrder: false,
    order: "",
    showExp: false,
    expTxt: "",
  };
  getQuestions = async () => {
    if (this.props.user.user_type === "3") {
      let result = await axiosApiInstance.get(
        APILINK + `/training_questions/${this.props.match.params.id}?page=1`
      );
      console.log(result);
      this.setState({
        questions: result.data.data,
        loading: false,
        count: result.data.count,
      });
    } else {
      let result = await axiosApiInstance
        .get(APILINK + `/training_questions/${this.props.match.params.id}`)
        .catch((err) => {
          if (err.response) {
            if (err.response.data.detail == "subscription is expired") {
              this.setState({
                showWarning: true,
              });
            }
            console.log(err.response.data);
          }
        });
      if (result) {
        this.setState({
          questions: result.data,
          loading: false,
          questionCount: result.data.length,
        });
      }
    }
  };

  componentDidMount() {
    this.setState({ loading: true });

    this.getQuestions();
  }

  next = () => {
    const currentId = this.state.questions[this.state.count + 1].id;
    this.setState({
      count: this.state.count + 1,
      checkAnswer: this.state.answerIds[currentId] ?? "",
    });
  };
  prev = () => {
    const currentId = this.state.questions[this.state.count - 1].id;

    this.setState({
      count: this.state.count - 1,
      checkAnswer: this.state.answerIds[currentId] ?? "",
    });
  };

  selectedAnswer = (e, id) => {
    console.log("radio checked", e.target.id);
    console.log("e.target.id ", id);
    console.log("e.target.value ", e.target.value);
    const copy = { ...this.state.answerIds, [id]: e.target.value };

    this.setState({
      answerID: e.target.value,
      answerIds: copy,
      checkAnswer: e.target.id,
    });
  };
  showDeleteWarning = (e) => {
    this.setState({ show: true, quesID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteTraining = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/question_del/${this.state.quesID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ show: false });
      this.getQuestions();
    }
  };

  deleteChoice = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_question_choice/${e.target.id}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ show: false });
      this.getQuestions();
    }
  };

  editQues = async (e) => {
    let data = {};
    data.question = this.state.question;
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      data
    );
    if (result) {
      this.setState({ question: "", showEdit: false });
      this.getQuestions();
    }
  };
  editChoice = async (e) => {
    let data = {};
    data.choice = this.state.choice;
    // data.is_answer=this.state.isCorrect
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question_choice/${this.state.choiceId}`,
      data
    );
    if (result) {
      this.setState({ choice: "", showEditChoice: false });
      this.getQuestions();
    }
  };

  viewAdd = (e) => {
    this.setState({ showAddModal: true, quesID: e.target.id });
  };
  hideAdd = () => {
    this.setState({ showAddModal: false });
  };
  handleChange = (e) => {
    console.log(e);
    this.setState({ [e.target.name]: e.target.value });
  };
  addChoice = async () => {
    let data = {
      question_id: this.state.quesID,
      choice: this.state.choice,
    };
    console.log(data);
    let result = await axiosApiInstance
      .post(APILINK + `/create_question_choice`, data)
      .catch((err) => {
        console.log(err.response);
      });
    console.log(result);
    if (result) {
      if (result.data) {
        this.setState({ showAddModal: false, choice: "" });
        this.getQuestions();
      }
    }
  };

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        const result = await axiosApiInstance
          .get(
            APILINK +
              `/training_questions/${this.props.match.params.id}?page=${this.state.current}`
          )
          .then((res) => {
            console.log("questionsssssssss", res.data.data);
            this.setState({
              questions: res.data.data,
              loading: false,
              count: res.data.count,
            });
          });
      }
    );
  };
  openExplain = (e) => {
    this.setState({ showExplain: true });
  };
  closeExplain = (e) => {
    this.setState({ showExplain: false });
  };
  done = () => {
    message.success("You have answered all training questions", 13);
  };
  go = (e) => {
    e.preventDefault();
    if (this.state.questionNumber > this.state.questionCount) {
      toast.error(
        `Value must be less than or equal ${this.state.questionCount}`
      );
    } else {
      this.setState({ count: this.state.questionNumber - 1, checkAnswer: "" });
    }
  };
  activateQues = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/activate_training/${this.props.match.params.id}`)
      .catch((err) => {
        console.log(err.response);
        setTimeout(() => {
          toast.error(
            "You will be able to activate the questions after paying the last installment!"
          );
        }, 2000);
      });
    console.log(result);
    if (result) {
      if (result.data == "is activated") {
        this.setState({ showWarning: false });

        this.getQuestions();
      }
    }
  };

  showEditModel = (e, ques) => {
    console.log(e, ques);
    this.setState(
      { showEdit: true, quesID: e.target.id, selectedQuestion: ques },
      () => {
        console.log(this.state.quesID, this.state.selectedQuestion);
      }
    );
  };
  hideEdit = () => {
    this.setState({ showEdit: false });
  };
  showEditChoiceModel = (e) => {
    this.setState({ showEditChoice: true, choiceId: e.target.id });
  };
  hideEditChoice = () => {
    this.setState({ showEditChoice: false });
  };
  showEditImgModel = (e) => {
    this.setState({ showEditImg: true, quesID: e.target.id });
  };

  onFileUpload = (e) => {
    e.preventDefault();

    this.setState({
      selectedFile: e.target.files[0],
      fileName: e.target.files[0].name,
    });
  };
  EditImage = async () => {
    let eventdata = new FormData();

    eventdata.append("image", this.state.selectedFile);

    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      eventdata
    );
    if (result) {
      this.setState({ question: "", showEditImg: false });
      this.getQuestions();
    }
  };
  handleISCorrect = (e) => {
    this.setState({ isCorrect: e.target.checked });
  };
  hideImg = () => {
    this.setState({ showEditImg: false });
  };
  showOrderModal = (e, order) => {
    this.setState({ showEditOrder: true, quesID: e.target.id, order: order });
  };
  showExplainAdmin = (e, exp) => {
    this.setState({ showExp: true, quesID: e.target.id, expTxt: exp });
  };
  hideExpAdmin = () => {
    this.setState({ showExp: false, expTxt: "" });
  };
  editOrder = async () => {
    let data = {
      display_order: this.state.order,
    };
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      data
    );
    if (result) {
      this.setState({ showEditOrder: false });
      this.getQuestions();
    }
  };
  hideOrderModal = () => {
    this.setState({ showEditOrder: false });
  };
  editExp = async () => {
    let data = {
      explanation: this.state.expTxt,
    };
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      data
    );
    if (result) {
      this.setState({ showExp: false });
      this.getQuestions();
    }
  };

  // flag1 =
  //   choice.is_answer &&
  //   this.state.checkAnswer &&
  //   this.state.answerID == choice.id
  //     ? true
  //     : false;

  // flag2 =
  //   choice.is_answer == true && this.state.checkAnswer === false ? true : false;

  render() {
    return (
      <div>
        {this.props.user.user_type === "2" ? (
          <Container className="content-margin">
            <Row>
              <Col md="6" sm="12">
                <h4 style={{ color: "#124E68" }}>
                  <i
                    onClick={() => this.props.history.goBack()}
                    className="fas fa-chevron-left pr-4"
                  ></i>
                  Questions
                </h4>
              </Col>
              <Col sm="12" md="6" className="mt-3">
                <form onSubmit={(e) => e.preventDefault}>
                  <input
                    value={this.state.questionNumber}
                    name="questionNumber"
                    onChange={this.handleChange}
                    max={this.state.questionCount}
                    type="number"
                    className="ant-input questionNumberInput"
                    placeholder="Pick a Question"
                  />
                  <input
                    value="Pick a Question"
                    className="forumBtn1 p-1 ml-2"
                    onClick={this.go}
                    type="submit"
                  />
                </form>
              </Col>
            </Row>
            {this.state.questions.map((ques, index) => {
              return this.state.count == index ? (
                <React.Fragment>
                  <Row>
                    <Col sm="12" md="12">
                      <div className="questionDiv p-4">
                        <p>{ques.question}</p>
                        {ques.image == "" || ques.image == null ? null : (
                          <img
                            src={APILINK + "/media/" + ques.image}
                            className="img-fluid"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {ques.choices_.map((choice) => {
                      // console.table({
                      //   que: ques.id,
                      //   selectedChoice: this.state.answerIds[ques.id],
                      //   currentChoice: choice.id,
                      // });

                      return (
                        <Col className="my-3" sm="12">
                          <p className="choices1">
                            <Radio.Group
                              onChange={(e) => this.selectedAnswer(e, ques.id)}
                              value={this.state.answerIds[ques.id]}
                              name={choice.id}
                            >
                              <Radio
                                id={choice.is_answer}
                                value={choice.id}
                                checked={
                                  this.state.answerIds[ques.id] == choice.id
                                }
                              ></Radio>
                            </Radio.Group>
                            {choice.choice}
                            {/* // flag1 =
  //   choice.is_answer &&
  //   this.state.checkAnswer &&
  //   this.state.answerID == choice.id
  //     ? true
  //     : false;

  // flag2 =
  //   choice.is_answer == true && this.state.checkAnswer === false ? true : false; */}

                            {choice.is_answer == true &&
                            this.state.checkAnswer !== "" ? (
                              <React.Fragment>
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {console.log(
                                    this.state.answerID == choice.id,
                                    this.state.answerID,
                                    choice.id,
                                    choice
                                  )}
                                  Correct
                                </span>
                                {ques.explanation == "" ||
                                ques.explanation == null ? null : (
                                  <span
                                    className="explaination"
                                    onClick={this.openExplain}
                                  >
                                    View Explanation
                                  </span>
                                )}
                              </React.Fragment>
                            ) : choice.is_answer == false &&
                              this.state.checkAnswer !== "" &&
                              (this.state.answerID == choice.id || this.state.answerIds[ques.id] == choice.id) ? (
                              <React.Fragment>
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Wrong
                                </span>
                              </React.Fragment>
                            ) : null}
                          </p>
                        </Col>
                      );
                    })}
                  </Row>

                  <Modal
                    show={this.state.showExplain}
                    onHide={this.closeExplain}
                  >
                    <Container className="p-4">
                      <Row>
                        <Col className="text-center" sm={12}></Col>
                        <Row style={{ margin: "auto" }}>
                          <Col md={12}>
                            <p>{ques.explanation}</p>
                          </Col>
                        </Row>
                      </Row>
                    </Container>
                  </Modal>
                </React.Fragment>
              ) : null;
            })}
            {this.state.questions.length == 0 ? <Empty /> : null}
            <Row>
              <Col className="text-center mt-5" sm="12">
                {this.state.showWarning ? (
                  <>
                    <h5>
                      Warning: once you activate the NAVLE questions you will
                      have access for one year only. Activate these questions
                      only if you are already preparing for the NAVLE.
                    </h5>
                    <Button onClick={this.activateQues} className="forumBtn1">
                      Activate
                    </Button>
                  </>
                ) : null}
              </Col>
            </Row>
            {this.state.questions.length > 1 ? (
              <Row>
                <Col sm="12 mt-3 text-right p-3">
                  <Button
                    disabled={this.state.count == 0}
                    onClick={this.prev}
                    className="quesBtn1"
                  >
                    Back
                  </Button>

                  {this.state.questionCount == this.state.count + 1 ? (
                    <Button
                      // disabled={true}
                      onClick={this.done}
                      className="quesBtn2"
                    >
                      Done
                    </Button>
                  ) : (
                    <Button
                      disabled={this.state.questionCount == 0}
                      onClick={() => this.next()}
                      className="quesBtn2"
                    >
                      Next
                    </Button>
                  )}
                </Col>
              </Row>
            ) : null}
          </Container>
        ) : (
          <Container className="content-margin">
            <Row>
              <Col sm="6">
                <h4
                  onClick={() => this.props.history.goBack()}
                  style={{ color: "#124E68" }}
                >
                  <i className="fas fa-chevron-left pr-4"></i>Training Questions
                  Admin
                </h4>
              </Col>

              <Col sm="6">
                <Link to={`/create_questions/${this.props.match.params.id}`}>
                  <Button className="forumBtn1 mx-4">
                    Add Anther Question{" "}
                  </Button>
                </Link>

                <Button
                  className="forumBtn1"
                  onClick={async () =>
                    await generateQuestionsExcel(this.state.questions)
                  }
                >
                  Download As Excel File
                </Button>
              </Col>
            </Row>
            {this.state.questions.map((ques, index) => {
              return (
                <div className="questionDiv mb-4" style={{ width: "90%" }}>
                  <Row>
                    <Col md="9" xs="12">
                      <p>{ques.question}</p>
                      {ques.image == "" || ques.image == null ? (
                        <span className="p-4">
                          <i
                            id={ques.id}
                            onClick={this.showEditImgModel}
                            className="fas fa-edit px-1 editIcon"
                          />
                          Add image
                        </span>
                      ) : (
                        <img
                          src={APILINK + "/media/" + ques.image}
                          className="img-fluid"
                        />
                      )}
                    </Col>
                    <Col md="3" xs="12" className="p-4 mb-2">
                      <i
                        id={ques.id}
                        onClick={(e, q) => this.showEditModel(e, ques.question)}
                        className="fas fa-edit px-1 editIcon"
                      />
                      <i
                        onClick={this.showDeleteWarning}
                        id={ques.id}
                        className="fas fa-trash  editIcon"
                      ></i>
                    </Col>
                  </Row>

                  <Row>
                    {ques.choices_.map((choice) => {
                      return (
                        <Col className="my-3" sm="12">
                          <p className="choiceP">{choice.choice}</p>
                          <span style={{ color: "green" }}>
                            {choice.is_answer ? "(Correct answer)" : null}
                          </span>
                          <i
                            id={choice.id}
                            onClick={this.showEditChoiceModel}
                            className="fas fa-edit px-1 editIcon"
                          />
                          <i
                            onClick={this.deleteChoice}
                            id={choice.id}
                            className="fas fa-trash editIcon"
                          ></i>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col md="12" xs="12" className="p-3 text-right">
                      <Button
                        onClick={this.viewAdd}
                        id={ques.id}
                        className="forumBtn1"
                      >
                        Add Choice
                      </Button>
                      <Button
                        onClick={(e, q) =>
                          this.showOrderModal(e, ques.display_order)
                        }
                        id={ques.id}
                        className="forumBtn1 mx-1"
                      >
                        Change Question Order
                      </Button>
                      <Button
                        onClick={(e, q) =>
                          this.showExplainAdmin(e, ques.explanation)
                        }
                        id={ques.id}
                        className="forumBtn1 mx-1"
                      >
                        View/Edit Explaination
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
            {this.state.loading ? (
              <Col className="mt-5 text-center" sm="12">
                {" "}
                <Spin tip="...loading" />
              </Col>
            ) : this.state.questions.length === 0 &&
              this.state.loading == false ? (
              <Empty />
            ) : null}
            <Row className="p-4">
              {this.state.questions.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Question?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteTraining}
                    className="courseBtn1 mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="courseBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <Modal
          className="text-center"
          show={this.state.showAddModal}
          onHide={this.hideAdd}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <Input.TextArea
                  rows={4}
                  className="questInput"
                  onChange={(e) => {
                    this.setState(
                      { choice: e.target.value, disable: false },
                      () => {
                        console.log(this.state.choice);
                      }
                    );
                  }}
                  placeholder="Add choice"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button
                  disabled={this.state.disable}
                  className="forumBtn1"
                  onClick={this.addChoice}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center"
          show={this.state.showEdit}
          onHide={this.hideEdit}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <Input.TextArea
                  rows={4}
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ question: e.target.value, disable: false });
                  }}
                  placeholder="Edit Question hereee"
                  defaultValue={this.state.selectedQuestion}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button
                  disabled={this.state.disable}
                  className="forumBtn1"
                  onClick={this.editQues}
                >
                  save changes
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center"
          show={this.state.showEditChoice}
          onHide={this.hideEditChoice}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <Input.TextArea
                  rows={4}
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ choice: e.target.value, disable: false });
                  }}
                  placeholder="Edit Choice hereee"
                />
                {/* <Checkbox name="is_answer" onChange={this.handleISCorrect}>
                  Correct Answer
                </Checkbox> */}
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button
                  disabled={this.state.disable}
                  className="forumBtn1"
                  onClick={this.editChoice}
                >
                  save changes
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <ToastContainer position="bottom-right" />

        <Modal
          className="text-center"
          show={this.state.showEditImg}
          onHide={this.hideImg}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h5>Edit question image</h5>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div class="wrapper">
                  <div class="file-upload2">
                    <input onChange={this.onFileUpload} type="file" />
                    <i class="fa fa-plus"></i>
                  </div>
                </div>{" "}
                <p>{this.state.fileName}</p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button className="forumBtn1 mx-2" onClick={this.EditImage}>
                  Save Changes
                </Button>
                <Button className="forumBtn1" onClick={this.hideImg}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center"
          show={this.state.showEditOrder}
          onHide={this.hideOrderModal}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <Input
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ order: e.target.value });
                  }}
                  placeholder="Edit Order"
                  defaultValue={this.state.order}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button className="forumBtn1" onClick={this.editOrder}>
                  Save Changes
                </Button>
                <Button
                  className="forumBtn1 mx-2"
                  onClick={this.hideOrderModal}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal show={this.state.showExp} onHide={this.hideExpAdmin}>
          <Container fluid className="p-4">
            <Row>
              <Col className="text-center" sm={12}></Col>

              <p></p>
            </Row>
            <Row>
              <Col className="text-center" sm="12">
                <Input.TextArea
                  rows={6}
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ expTxt: e.target.value });
                  }}
                  placeholder="Add/Edit the explaination here.."
                  defaultValue={this.state.expTxt}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button className="forumBtn1" onClick={this.editExp}>
                  Save Changes
                </Button>
                <Button className="forumBtn1 mx-2" onClick={this.hideExpAdmin}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps)(
  ErrorHandler(ViewTrainingQues, axiosApiInstance)
);
