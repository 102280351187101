import { toast } from "react-toastify";

function formatKey(key) {
  const formatted = key
    .split("_")
    .map((it) => `${it[0].toUpperCase()}${it.slice(1)}`)
    .join(" ");
  return formatted;
}

export default function toastError(error) {
  Object.keys(error).forEach((key) => {
    toast.error(`${formatKey(key)}: ${error[key]}`);
  });
}
