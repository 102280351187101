import React, { Component } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
import { Col, Container, Row } from "react-bootstrap";
import { APILINK } from "../../EndPoint";
import { Calendar, Alert, Badge, Spin } from "antd";
import CalenderDetails from "./CalenderDetails";
// import DaysRequests from "./DaysRequests";
import moment from "moment";
const axiosApiInstance = axios.create();
class CalendarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          title: "test-event",
          date: "2021-03-22"
        }
      ],
      value: moment(),
      selectedValue: moment(),
      eventsDays: [],
      selectedDateData: "",
      is_new: false,
      loading: false,
      daysData: [],
      meetings: [],
      quizes: [],
      selectedDay:""
    };
  }
  changeLoader = (e) => {
    this.setState({ loading: e });
  };

  formatDate = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  componentDidMount = async () => {
    const result = await axiosApiInstance.get(
      APILINK + `/calender?month=${this.state.selectedValue.format("MM")}`
    );

    this.setState({
      daysData: result.data.subject_rounds,
      meetings: result.data.meeting,
      quizes: result.data.quiz
    });
    if (result) {
      const eventsDays = [];
      let f = result.data.subject_rounds.map((date) => {
        let char = date.start_date.date.split("-")[2];
        if (char[0] == "0") {
          char = char[1];
        }

        eventsDays.push(parseInt(char));
      });

      const quizDays = [];
      let day = result.data.quiz.map((date) => {
        let char = date.start_date.date.split("-")[2];
        if (char[0] == "0") {
          char = char[1];
        }

        quizDays.push(parseInt(char));
      });

      const lectureDays = [];
      let element = result.data.meeting.map((date) => {
        let char = date.date.date.split("-")[2];
        if (char[0] == "0") {
          char = char[1];
        }

        lectureDays.push(parseInt(char));
      });

      let arr = [...eventsDays, ...quizDays,...lectureDays];
      this.setState({ eventsDays: arr });
    }

    let events = document.getElementsByClassName("events");
    let element = document.getElementsByClassName("ant-picker-calendar-date");
    let eventsList = [...events];
    let ElementList = [...element];
    // console.log(ElementList);
    eventsList.map((child, index) => {
      // console.log(child.childNodes);
      if (child.childNodes.length !== 0) {
        // console.log(ElementList[index]);
        ElementList[index].className += " selectedEvents";
      }
    });
  };

  getListData = (value) => {
    let listData = [];
    // console.log(value.date())

    let t = moment(value._d).format("YY-MM-DD").substring(0, 5);
    let curr_t = this.state.selectedValue;

    curr_t = moment(curr_t).format("YY-MM-DD").substring(0, 5);

    if (this.state.eventsDays && t === curr_t) {
      if (this.state.eventsDays.includes(value.date())) {
        listData = [
          {
            type: "success",
            content: "Action needed"
          }
        ];
      }
    }
    return listData || [];
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.selectedValue !== this.state.selectedValue) {
      let element = document.getElementsByClassName("ant-picker-calendar-date");
      let ElementList = [...element];
      ElementList.map((element) => {
        element.classList.remove("selectedEvents");
      });
      const result = await axiosApiInstance.get(
        APILINK + `/calender?month=${this.state.selectedValue.format("MM")}`
      );
      // console.log(result);
      if (result) {
        this.setState({
          daysData: result.data.subject_rounds,
          meetings: result.data.meeting,
          quizes: result.data.quiz
        });
        const eventsDays = [];
        result.data.subject_rounds.map((date) => {
          let char = date.start_date.date.split("-")[2];
          if (char[0] == "0") {
            char = char[1];
          }
          eventsDays.push(parseInt(char));
        });

        const quizDays = [];
        let day = result.data.quiz.map((date) => {
          let char = date.start_date.date.split("-")[2];
          if (char[0] == "0") {
            char = char[1];
          }

          quizDays.push(parseInt(char));
        });

        const lectureDays = [];
        let element = result.data.meeting.map((date) => {
          let char = date.date.date.split("-")[2];
          if (char[0] == "0") {
            char = char[1];
          }
  
          lectureDays.push(parseInt(char));
        });
  
        let arr = [...eventsDays, ...quizDays,...lectureDays];
        this.setState({ eventsDays: arr });
      }

      let events = document.getElementsByClassName("events");
      element = document.getElementsByClassName("ant-picker-calendar-date");
      let eventsList = [...events];
      ElementList = [...element];
      eventsList.map((child, index) => {
        if (child.childNodes.length !== 0) {
          ElementList[index].className += " selectedEvents";
        }
      });
    }
  };

  onSelect = (value) => {
    this.setState({
      value,
      selectedValue: value,
      loading: true
    });
    // console.log(this.state.daysData);
    
    this.setState({ selectedDateData: this.state.daysData, loading: false,selectedDay:value.format("YYYY-MM-DD") });

    if (this.state.daysData.length !== 0) {
      let data = this.state.daysData.filter(
        (obj) => value.format("YYYY-MM-DD") === obj.start_date.date
      );
      // console.log(data);
      if (data) {
        this.setState({ selectedDateData: data, loading: false });
      }
    }
  };

  onPanelChange = (value) => {
    this.setState({ value });
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    // console.log(listData);

    return (
      <ul className="events">
        {listData.map((item) => (
          <li style={{ listStyle: "none" }} key={item.content}>
            {/* <Badge status={item.type} text={item.content} /> */}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <div>
        <Container className="py-3 studentcalender">
          <Row>
            <Col sm={8}>
              <Alert
                message={`You selected date: ${
                  // this.state.selectedValue &&
                  this.state.selectedValue.format("YYYY-MM-DD")
                }`}
              />
              <Calendar
                value={this.state.value}
                onSelect={this.onSelect}
                onPanelChange={this.onPanelChange}
                dateCellRender={this.dateCellRender}
                // monthCellRender={this.monthCellRender}
                fullscreen={false}
              />
            </Col>
            <Col sm={4}>
              <CalenderDetails
                meetings={this.state.meetings}
                details={this.state.selectedDateData}
                quizes={this.state.quizes}
                selectedDay={this.state.selectedDay}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ErrorHandler(CalendarComponent, axiosApiInstance);
