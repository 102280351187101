import React, { Component } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import "../../style-sheets/training.css";
import { message, Radio, Space } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Input, Pagination } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { Link, Prompt } from "react-router-dom";
import WrongAnswersModal from "../sections/WrongAnswersModal";
import {axiosApiInstanceQuiz} from "./ViewAllQuizes";

const axiosApiInstance = axiosApiInstanceQuiz;

class ViewQuizQues extends Component {
  message = "Are you sure you want to leave? you didn't submit your quiz yet.";
  interval = null;
  timerFormat = {
    minimumIntegerDigits: 2,
    useGrouping: false,
  };
  state = {
    loading: false,
    questions: [],
    count: 0,
    checkAnswer: "",
    quizes: [{ question: "", answer: "" }],
    questionCount: 0,
    results: "",
    QuizResults: "",
    choice: "",
    showAddModal: false,
    disable: true,
    current: 1,
    question: "",
    showEdit: false,
    showEditChoice: false,
    choiceId: "",
    showDelete: false,
    showEditImg: false,
    selectedFile: null,
    dateTaken: "",
    duration: "",
    hours: "",
    mins: "",
    minsShow: "",
    sec: "",
    secShow: "",
    answerID: [],
    showAnswersModal: false,
    timeout: false,
    formSubmitQuiz: false,
    questionIndex: 0,
    allAns: [],
    quesId: "",
    ansId: "",
    confirmSubmit: false,
    ansObj: "",
  };
  getQuestions = async () => {
    this.setState({ loading: true });
    let result = await axiosApiInstance
      .get(
        APILINK + `/quiz_questions/${this.props.match.params.id}?page_size=800`
      )
      .then((res) => {
        this.setState({
          questions: res.data.data,
          loading: false,
          questionCount: res.data.count,
          dateTaken: res.data.date_taken,
          duration: res.data.duration,
          nextUrl: res.data.next,
        });
        // this.getRemainingTime();
      })
      .catch((err) => {
        console.log({ error: err.response.data });
        if (err.response.data == "Quiz is not active yet or already expired") {
          message.error("Quiz is not active yet or already expired");
        } else {
          this.setState({ QuizResults: err.response.data });
        }
        console.log(err.response);
      });
  };

  componentDidMount() {
    window.onbeforeunload = () => {
      return this.message;
    };

    this.getQuestions();
    this.interval = setInterval(() => {
      if (!this.state.loading) this.getRemainingTime();
    }, 1000);
  }

  componentWillUnmount() {
    if (!this.state.results && !this.state.QuizResults) {
      console.log("Submitting");
      this.submitQuiz().then((res) => {
        console.log(res);
      });
    }
  }

  next = () => {
    console.log(
      "next",
      this.state.questionIndex + 1,
      this.state.questions.length
    );
    this.setState({ questionIndex: this.state.questionIndex + 1 });
  };
  prev = () => {
    console.log("prev");

    this.setState({ questionIndex: this.state.questionIndex - 1 });
  };

  selectedAnswer = (e) => {
    console.log("q:", e.target.id, "a:", e.target.value);

    this.setState({
      quesId: e.target.id,
      ansId: e.target.value,
    });

    var foundIndex = this.state.allAns.findIndex(
      (x) => x.question == e.target.id
    );

    console.log("foundIndex", foundIndex === -1 ? false : true);

    if (foundIndex === -1) {
      this.setState(
        {
          ansObj: { question: e.target.id, answer: e.target.value },
        },
        () => {
          this.setState(
            { allAns: [...this.state.allAns, this.state.ansObj] },
            () => {
              console.log("all", this.state.allAns);
              console.log("ansObj:", this.state.ansObj);
            }
          );
        }
      );
    } else {
      console.log(true);
      let items = [...this.state.allAns];
      items.forEach((element, index) => {
        if (element.question == e.target.id) {
          items[index] = { question: e.target.id, answer: e.target.value };

          this.setState({ allAns: items }, () => {
            console.log("all", this.state.allAns);
          });
        }
      });
    }
  };

  submitQuiz = async () => {
    let data = {
      quiz_id: this.props.match.params.id,
      quizes: this.state.allAns,
    };

    console.log("allAns:", this.state.allAns);
    // console.log("quesId:", this.state.quesId);
    // console.log("ansId:", this.state.ansId);

    // console.log("ansObj:", this.state.ansObj);

    let result = await axiosApiInstance
      .post(APILINK + "/submit_quiz", data)
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          // if (err.respone.data == "this is graded already") {
          setTimeout(() => {
            toast.error(err.response.data);
          }, 500);
          // }
        }
      });
    if (result) {
      console.log("result after submit");
      console.log(result);
      this.setState({
        showModal: true,
        results: result.data,
        formSubmitQuiz: true,
      });
    }
  };

  submitQuizManually = async (confirmSubmit = false) => {
    console.log({
      questions: this.state.questions.length,
      allAns: this.state.allAns.length,
      confirmSubmit,
    });
    if (
      this.state.questions.length === this.state.allAns.length ||
      confirmSubmit
    ) {
      await this.submitQuiz();
    } else {
      this.setState({ showSubmitConfirm: true });
    }
  };

  handleChange = (e) => {
    console.log(e);
    this.setState({ [e.target.name]: e.target.value });
  };

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        const result = await axiosApiInstance
          .get(
            APILINK +
              `/quiz_questions/${this.props.match.params.id}?page=${this.state.current}`
          )
          .then((res) => {
            console.log(res);
            this.setState({
              questions: res.data.data,
              loading: false,
              questionCount: res.data.count,
              dateTaken: res.data.date_taken,
              duration: res.data.duration,
            });
          });
      }
    );
  };
  hideGrade = (e) => {
    this.props.history.goBack();
    this.setState({ showModal: false });
    this.setState({
      questions: [],
      loading: false,
      questionCount: 0,
    });
    this.getQuestions();
  };

  dateDiffInDays(a, b, maxHours) {
    const _MS_PER_HOURS = 1000 * 60 * 60;

    const utc1 = Date.UTC(
      a.getFullYear(),
      a.getMonth(),
      a.getDate(),
      a.getHours(),
      a.getMinutes(),
      a.getSeconds()
    );
    const utc2 = Date.UTC(
      b.getFullYear(),
      b.getMonth(),
      b.getDate(),
      b.getHours(),
      b.getMinutes(),
      b.getSeconds()
    );
    const utc3 = new Date(utc2 - utc1);
    const utc4 = new Date(utc2 - utc1);
    utc3.setSeconds(+utc3.getSeconds() + 10);

    const differenceHours = Math.floor((utc2 - utc1) / _MS_PER_HOURS) ?? 0;

    return {
      hours: Math.min(differenceHours, maxHours),
      mins: +utc3.getMinutes(),
      sec: +utc3.getSeconds(),
      minsShow: +utc4.getMinutes(),
      secShow: +utc4.getSeconds(),
    };
  }

  getRemainingTime = () => {
    const serverDateString = this.state.dateTaken.replace("Z", "") + "+00:00";
    let date1 = new Date(serverDateString);
    let date2 = new Date();
    let diff = date2.getTime() - date1.getTime();
    let date3 = new Date(diff);

    let timerEndHour = +this.state.duration.split(":")[0];
    let timerEndMins = +this.state.duration.split(":")[1] ?? 60;
    // let dateState = {
    //     hours: Math.min(date3.getHours() - 2, timerEndHour),
    //     mins: +date3.getMinutes(),
    //     sec: +date3.getSeconds(),
    // };
    const dateState = this.dateDiffInDays(date1, date2, timerEndHour);

    // console.log("currentdate", currentdate);

    // console.log("time1", dateState.hours);
    // console.log("time2", timerEndHour);

    // console.log(`duration: ${timerEndHour}, hours: ${dateState.hours}`)
    if (dateState.hours >= timerEndHour && dateState.mins >= timerEndMins) {
      this.setState({ timeout: true });

      console.log(`{timeout: ${this.state.timeout}}`);
      // this.interval.cance
      // console.log(`{ss: ${this.data}}`)
      if (!this.state.loading) {
        clearInterval(this.interval);
        this.submitQuiz();
      }
      return;
    }
    this.setState(dateState);
    // console.log(this.state.dateTaken);

    // console.log(date2.getTime() - date1.getTime());
    // console.log(
    //   "difference",
    //   // date2.getHours(),
    //   // date2.getMinutes(),
    //   // date2.getSeconds()
    //   diff
    // );
  };
  go = (e) => {
    e.preventDefault();
    if (this.state.questionNumber > this.state.questionCount) {
      toast.error(
        `Value must be less than or equal ${this.state.questionCount}`
      );
    } else {
      this.setState({ questionIndex: this.state.questionNumber - 1 });
    }
  };
  showAnswers = (e) => {
    this.setState({ showAnswersModal: true });
  };
  hideAnswersModal = (e) => {
    this.setState({ showAnswersModal: false });
  };

  render() {
    return (
      <div>
        {!this.state.results && !this.state.QuizResults && (
          <Prompt when={true} message={() => this.message} />
        )}

        {this.state.timeout ? (
          <div
            style={{ minHeight: "50vh" }}
            className="d-flex align-items-center justify-content-center "
          >
            <Container className="content-margin p-4">
              <Row className="questionDiv p-4">
                <Row>
                  <h4 className="">
                    We are sorry but timeout, Your quiz is submitted
                    automatically.
                  </h4>
                </Row>
                {this.state.results !== "" &&
                  this.state.results !== undefined && (
                    <>
                      <Col sm="6">
                        {" "}
                        <p style={{ fontSize: "25px" }}>
                          <strong className="pr-3">Grade</strong>
                          {this.state.results.grade}
                        </p>
                      </Col>
                      <Col style={{ fontSize: "25px" }} sm="6">
                        <strong className="pr-3">Status</strong>
                        {this.state.results.passed ? (
                          <strong style={{ color: "green" }}>Passed</strong>
                        ) : (
                          <strong style={{ color: "red" }}>Failed</strong>
                        )}
                      </Col>
                    </>
                  )}
              </Row>
            </Container>
          </div>
        ) : (
          <Container className="content-margin">
            <Row>
              <Col sm="6">
                <h4 style={{ color: "#124E68" }}>
                  <i
                    onClick={() => this.props.history.goBack()}
                    className="fas fa-chevron-left pr-4"
                  ></i>
                  Questions
                </h4>
              </Col>
              <Col sm="12" md="6" className="my-3">
                <form onSubmit={(e) => e.preventDefault}>
                  <input
                    value={this.state.questionNumber}
                    name="questionNumber"
                    onChange={this.handleChange}
                    max={this.state.questionCount}
                    type="number"
                    className="ant-input questionNumberInput"
                    placeholder="Pick a Question"
                  />
                  <input
                    value="Pick a Question"
                    className="forumBtn1 p-1 ml-2"
                    onClick={this.go}
                    type="submit"
                  />
                </form>
              </Col>
              {this.state.QuizResults == "" &&
              this.state.questions.length !== 0 ? (
                <Col sm="5">
                  <h6 style={{ color: "#124E68" }}>
                    <i class="fas fa-clock"></i> Time passed:{"  "}
                    {(+this.state.hours).toLocaleString(
                      "en-US",
                      this.timerFormat
                    )}
                    :
                    {(+this.state.minsShow).toLocaleString(
                      "en-US",
                      this.timerFormat
                    )}
                    :
                    {(+this.state.secShow).toLocaleString(
                      "en-US",
                      this.timerFormat
                    )}{" "}
                    out of {this.state.duration} hours
                  </h6>
                </Col>
              ) : null}
            </Row>
            {this.state.QuizResults !== "" ? (
              <div className="questionDiv">
                <Container className="p-4">
                  <Row>
                    <Col sm="6">
                      {" "}
                      <p>
                        <strong className="pr-3">Grade</strong>
                        {this.state.QuizResults.grade}
                      </p>
                    </Col>
                    <Col sm="6">
                      {" "}
                      <p>
                        <strong className="pr-3">Status</strong>
                        {this.state.QuizResults.state
                          ? this.state.QuizResults.state
                          : null}
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            ) : null}
            {this.state.questions.length !== 0 ? (
              <React.Fragment>
                {this.state.questions.map((q, i) => (
                  <div
                    className={`${
                      this.state.questionIndex !== i ? "d-none" : ""
                    }`}
                  >
                    <Col sm="8">
                      <div className="questionDiv p-4">
                        <p>{q.question}</p>
                        {q.image == "" || q.image == null ? null : (
                          <img
                            src={APILINK + "/media/" + q.image}
                            className="img-fluid"
                          />
                        )}
                      </div>
                    </Col>

                    <Col className="my-3" sm="12">
                      <p className="choices1">
                        <Radio.Group
                          onChange={(e) => this.selectedAnswer(e)}
                          // value={this.state.answerID[index]}
                          // name={q.id}
                        >
                          <Space direction="vertical">
                            {q.choices_.map((choice) => (
                              <Radio
                                id={choice.question}
                                value={choice.id}
                                className="py-3 "
                              >
                                {choice.choice}
                              </Radio>
                            ))}
                          </Space>

                          {/* <Radio id={q.id} value={choice.id}></Radio> */}
                        </Radio.Group>
                      </p>
                    </Col>
                  </div>
                ))}
                <Row>
                  <Col sm="12 mt-3 text-right p-3">
                    <>
                      {this.state.formSubmitQuiz ? null : (
                        <Button
                          disabled={this.state.questionIndex === 0}
                          onClick={this.prev}
                          className="quesBtn1"
                        >
                          Back
                        </Button>
                      )}
                    </>
                    {this.state.questionIndex + 1 ===
                    this.state.questions.length ? (
                      <>
                        {this.state.formSubmitQuiz ? null : (
                          <Button
                            className="quesBtn2"
                            onClick={() => this.submitQuizManually()}
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.formSubmitQuiz ? null : (
                          <Button onClick={this.next} className="quesBtn2">
                            Next
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            ) : this.state.QuizResults === "" ? (
              <Empty />
            ) : null}

            <Modal
              className="text-center"
              show={this.state.showModal}
              onHide={this.hideGrade}
            >
              <Container className="p-4">
                <Row>
                  <Col sm="6">
                    {" "}
                    <p style={{ fontSize: "25px" }}>
                      <strong className="pr-3">Grade</strong>
                      {this.state.results.grade}
                    </p>
                  </Col>
                  <Col style={{ fontSize: "25px" }} sm="6">
                    <strong className="pr-3">Status</strong>
                    {this.state.results.passed ? (
                      <strong style={{ color: "green" }}>Passed</strong>
                    ) : (
                      <strong style={{ color: "red" }}>Failed</strong>
                    )}
                  </Col>
                </Row>
                <p onClick={this.showAnswers} className="viewP">
                  View your wrong answers
                </p>
              </Container>
            </Modal>
          </Container>
        )}
        <Modal
          className=""
          show={this.state.showSubmitConfirm}
          onHide={() => {
            this.setState({ showSubmitConfirm: false });
          }}
          size="md"
        >
          <div className="confirmModal">
            <p>
              Are you sure you want to submit the quiz? You have some unanswered
              questions.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className="quesBtn1"
                onClick={() => {
                  this.submitQuizManually(true).then((r) => {
                    this.setState({ showSubmitConfirm: false });
                  });
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>

        <WrongAnswersModal
          show={this.state.showAnswersModal}
          hide={this.hideAnswersModal}
          results={this.state.results}
        />
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
export default connect(
  mapStateToProps,
  null
)(ErrorHandler(ViewQuizQues, axiosApiInstance));
