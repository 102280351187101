import React, { Component } from "react";
import { Button } from "antd";
export default class CalenderDetails extends Component {
  render() {
    console.log(this.props);
    return (
      <div>
        <h3 style={{ color: "#124E68" }}>Subjects</h3>
        <hr />
        {this.props.details
          ? this.props.details.map((data) => {
              return (
                <React.Fragment>
                  <div className="calenderBox my-3">
                    <p>
                      <strong>Subject </strong>
                      {data.subject.title}
                    </p>
                    <p>
                      <strong>Tutor </strong>
                      {data.tutor.full_name}
                    </p>
                    <p>
                      <strong>Start Time </strong>
                      {data.start_time}
                    </p>
                    <p>
                      <strong>Start Date </strong>
                      {data.start_date.date}
                    </p>
                    <p>
                      <strong>End Date </strong>
                      {data.end_date.date}
                    </p>

                    {/* 
                    {this.props.quizes
                      ? this.props.quizes.map((x) =>
                          data.id == x.subject_round ? (
                            <>
                              <hr style={{ backgroundColor: "white" }} />
                              <p>
                                {" "}
                                <strong>Quiz Title </strong>
                                {x.title}
                              </p>
                              <p>
                                {" "}
                                <strong>Quiz Date </strong>
                                {x.start_date.date}
                              </p>
                            </>
                          ) : null
                        )
                      : null} */}
                  </div>
                </React.Fragment>
              );
            })
          : null}

        {this.props.meetings && this.props.selectedDay !== ""
          ? this.props.meetings.map((x) =>
              x.date.date == this.props.selectedDay ? (
                <div className="calenderBox my-3">
                  <p className="lectureLink">
                    <strong>Lecture Title </strong>
                    {x.title}
                  </p>
                  <p className="lectureLink">
                    <strong>Lecture Zoom Link </strong>
                    <a href={`${x.link}`} target="_blank">
                      Click Here
                    </a>
                  </p>
                  <p>
                    {" "}
                    <strong>Lecture Date </strong>
                    {x.date.date}
                  </p>
                  <p>
                    {" "}
                    <strong>Lecture Start Time </strong>
                    {x.start_time}
                  </p>
                  <p>
                    {" "}
                    <strong>Lecture Duration </strong>
                    {x.duration}
                  </p>
                </div>
              ) : null
            )
          : null}

        {this.props.quizes && this.props.selectedDay !== ""
          ? this.props.quizes.map((x) =>
              x.start_date.date == this.props.selectedDay ? (
                <div className="calenderBox my-3">
                  <p>
                    {" "}
                    <strong>Quiz Title </strong>
                    {x.title}
                  </p>
                  <p>
                    {" "}
                    <strong>Quiz Date </strong>
                    {x.start_date.date}
                  </p>
                </div>
              ) : null
            )
          : null}
      </div>
    );
  }
}
