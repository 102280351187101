import React, { useState, useEffect } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Pagination, Spin, Image, Checkbox, Empty } from "antd";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();

const UnverifiedPayments = (props) => {
  const [showDelete, setShowDelete] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentID, setPaymentID] = useState("");

  const onChange = async (page) => {
    console.log(page);
    setLoading(true);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/pay_avec_account?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
    setLoading(false);
  };
  const deletePayment = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/pay_avec_account/${paymentID}`
    );
    if (result) {
      setShowDelete(false);

      getPayments();
    }
  };

  const getPayments = async () => {
    setLoading(true);
    let result = await axiosApiInstance
      .get(APILINK + `/pay_avec_account`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      setData(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    }
  };
  const openModal = (e) => {
    setShowDelete(true);
    setPaymentID(e.target.id);
  };
  const onChecked = async (e) => {
    let result = await axiosApiInstance.put(
      APILINK + `/pay_avec_account/${e.target.id}`,
      { checked: true }
    );
    console.log(result);
    if (result) {
      if (result.data) {
        setTimeout(() => {
          toast.info("Payment has been verified successfully");
        }, 500);
        getPayments();
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getPayments();
  }, []);
  return (
    <Container className="content-margin">
      <Row>
        <Col lg={12} md={12}>
          <h1 className="course-title">Students Payments</h1>
        </Col>
      </Row>
      {loading ? (
        <Spin className="spinElement" tip="..loading" />
      ) : data.length !== 0 ? (
        <React.Fragment>
          <Row>
            {data.map((payment) => {
              return (
                <Col lg={4} md={6} sm={12}>
                  <div className="payment-card">
                    <Image
                      className="card-img-top"
                      src={APILINK + payment.image}
                    />
                    {/* <p>
                      <strong>Cost : </strong>
                      {payment.cost}
                    </p> */}
                    <p>
                      <strong>Student : </strong>

                      {payment.student.full_name +
                        " " +
                        payment.student.last_name}
                    </p>
                    <p>
                      <strong>Course : </strong>
                      {payment.course_round?.course?.title}
                    </p>
                    <p>
                      <strong>Installment Type : </strong>
                      {payment.installment_policy === "1" ? "Half" : "Full"}
                    </p>
                    <Checkbox
                      id={payment.id}
                      defaultChecked={payment.checked}
                      onChange={onChecked}
                    >
                      Checked?
                    </Checkbox>
                    <i
                      id={payment.id}
                      onClick={openModal}
                      className="fas fa-trash"
                    ></i>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="p-4">
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Row>
        </React.Fragment>
      ) : (
        <Empty />
      )}
      <Modal
        className="text-center p-3"
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
      >
        <Container className="p-4">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>Are u sure u want to delete this Payment?</h6>
            </Col>
            <Row style={{ margin: "auto" }}>
              <Col md={12}>
                <Button onClick={deletePayment} className="forumBtn1 mx-3">
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    setShowDelete(false);
                  }}
                  className="forumBtn1"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Container>
      </Modal>
    </Container>
  );
};

export default ErrorHandler(UnverifiedPayments, axiosApiInstance);
