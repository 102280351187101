// export const APILINK = 'http://localhost:7777'
// export const APILINK = 'http://192.168.88.45:8000'
// export const APILINK = "https://back.avecian.com";
// for production
export const APILINK = "https://elearningback.div-systems.com";
// export const APILINK = "https://elearningback.div-systems.com";       
export const ADMIN_ENDPOINTS = {
  students: "/students_filter",
  deactivateStudent: ""
};
// export const APILINK = "https://e1ce-156-205-167-73.ngrok.io";
